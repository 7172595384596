import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="Image displaying each variation of button, from left: Secondary, Primary, Danger, Invisible." src="https://github.com/primer/design/assets/980622/8a02c179-61d1-48a6-8dd8-7a0ccdfecef7" />
    <h2>{`Usage`}</h2>
    <p>{`Buttons allow users to initiate an action or command when clicked or tapped. The button's label or text description indicates the purpose of the action to the user. At GitHub, buttons are a fundamental building block of our products. Most of the time, we use the `}<inlineCode parentName="p">{`secondary`}</inlineCode>{` variant, but other variants of buttons may be used to indicate something special about the button's hierarchy or functionality.`}</p>
    <h2>{`Anatomy`}</h2>
    <img width="960" alt="Anatomy of a button, including the label, leading visual, trailing visual, and trailing action" src="https://user-images.githubusercontent.com/586552/217702365-f8d6ce35-08e0-4d0b-a6d6-8af12d8ea8cf.png" />
    <h2>{`Options`}</h2>
    <h3>{`Leading and trailing visuals`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="trailing action on a button" src="https://user-images.githubusercontent.com/586552/217703058-f9ad11e2-162d-4073-bc7c-04a70890b84e.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Trailing actions</h4>
    Trailing actions such as dropdown carets are always locked to the end of a button, which is particularly obvious for full width scenarios. If a button is a call-to-action (Submit, Save, Do this™, etc), its contents (visuals and button label) appear center-aligned inside the button container. If a button is used for selecting items (Weeks ▾, Iteration ▾, Sort ▾, Select™ ▾, etc), its contents (visuals and button label) appear left-aligned inside the button container.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="leading visual on a button" src="https://user-images.githubusercontent.com/586552/217703061-a5d658ee-b5af-4bf7-8771-59f4190fb2ea.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Leading visuals</h4>
    Leading visuals provide additional context for a button label, such as a “search” icon next to the label for a search field submit. Leading visuals always appear locked to the button label, even if the button is full width.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="trailing visual on a button" src="https://user-images.githubusercontent.com/586552/217703062-40a5ef54-d89f-44b0-a7ab-5c7b23ec927b.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Trailing visuals</h4>
    Trailing visuals such as counters display additional information about the action or task at hand.
    Trailing visuals always apear locked to the button label, even if the button is full width.
  </Box>
    </Box>
    <h3>{`Sizing and spacing`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="button at medium size" src="https://user-images.githubusercontent.com/586552/217703064-c8e13e96-585b-4a91-a4e8-0059ef7dd57e.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Medium (default)</h4>
    Primer buttons are medium sized by default.
    Medium is suitable for most interfaces and is considered the preferred size.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="button at small size" src="https://user-images.githubusercontent.com/586552/217703067-8a732463-0c5d-4722-b075-c01813f00482.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Small</h4>
    Small buttons may be used when space is limited and if the action is less significant.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="button at large size" src="https://user-images.githubusercontent.com/586552/217703069-f37700df-22be-4e1a-a701-e34856927da6.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Large</h4>
    Large buttons increase the significance of an action and should be used sparingly.
    More often than not a medium sized button will be more appropriate.
  </Box>
    </Box>
    <h3>{`Variants`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="Primary button with green background" src="https://user-images.githubusercontent.com/586552/217703071-524b0574-2f03-444e-95df-6be46782ee47.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Primary</h4>
    Primary buttons represent the highest priority action in a page. There should only ever be one primary button (if any) in a button group, and typically only one primary button should be present in page.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="Secondary button with gray background" src="https://user-images.githubusercontent.com/586552/217703800-053ca7db-195b-4894-9114-907d6d56e5e1.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Secondary</h4>
    Secondary buttons are the default button color scheme and are suitable for more interactions. They can be paired with a primary button to perform a secondary action, or used on their own.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="Invisible button with transparent background" src="https://user-images.githubusercontent.com/586552/217703072-3d4fc9d5-687d-42bd-a819-326b24b70ae0.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Invisible</h4>
    Invisible buttons have a transparent background with translucent hover and active states, making them useful for compound components like ActionList.
  </Box>
    </Box>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 4
    }} mdxType="Box">
  <img width="456" alt="Danger button with red background" src="https://user-images.githubusercontent.com/586552/217703074-33192c43-21d5-44f4-abd3-e35c87ab8f7f.png" />
  <Box as="p" mt="0" mdxType="Box">
    <h4>Danger</h4>
    Danger buttons should be used sparingly to warn of a destructive action, typically resulting in the opening of a confirmation dialog.
  </Box>
    </Box>
    <h3>{`Inactive`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <img width="456" alt="An button that looks like a disabled primary button, but it has a focus state" src="https://github.com/primer/design/assets/2313998/167f846f-0a86-4f04-bac3-4af9843e8933" />
      <div>
        <p>{`An inactive button has styles that make the button appear "muted". The style is the same for all button variants.`}</p>
        <p>{`An inactive button is an accessible alternative to a disabled button. They're intended to be used for buttons that are non-functional, but cannot be removed from the page.`}</p>
        <p>{`Unlike a disabled button, an inactive button can respond to user input. For example, if a button shows a tooltip when hovered or focused, or a dialog when clicked.`}</p>
        <p>{`If you need to use an inactive button, it's best to have something else on the page that informs users about the issue that broke the button's normal functionality. For example, showing a `}<a parentName="p" {...{
            "href": "/ui-patterns/degraded-experiences#global-system-notification"
          }}>{`global banner`}</a>{` for service outages.`}</p>
      </div>
    </Box>
    <Box display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <CustomVideoPlayer width="456" loop src="https://github.com/primer/design/assets/2313998/b74267b7-3c7b-4343-88f8-fdfe6d076e31" mdxType="CustomVideoPlayer" />
      <Box sx={{
        flexBasis: 0,
        flexGrow: 1
      }} mdxType="Box">
        <p>{`There are two recommended ways an inactive button should respond to user input:`}</p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Show a dialog on click:`}</strong>{` When a user tries to activate an inactive the button, open a `}<a parentName="li" {...{
              "href": "/components/dialog"
            }}>{`dialog`}</a>{` that explains why the action cannot be completed and give them a path forward if possible. It is required to provide some kind of feedback when a user clicks a button.`}</li>
          <li parentName="ol"><strong parentName="li">{`Show a tooltip on hover or focus:`}</strong>{` Before a user tries to activate a non-functional control, a `}<a parentName="li" {...{
              "href": "/components/tooltip"
            }}>{`tooltip`}</a>{` with additional context may be displayed on hover or focus. It is `}<em parentName="li">{`not`}</em>{` required to respond to hover and focus.`}</li>
        </ol>
      </Box>
    </Box>
    <h3>{`Loading`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" justifyContent="space-between" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <img width="456" alt="An button that looks disabled, but the text is replaced with a spinning loading indicator" src="https://github.com/primer/design/assets/2313998/332baaba-6c0d-4d6c-b9cb-57616d35d9f2" />
      <div>
        <p>{`You may use a button loading state if we need to wait for a button's action to be completed. Refer to the accessibility section's `}<a parentName="p" {...{
            "href": "#button-loading-state"
          }}>{`button loading state`}</a>{` guidance for more information.`}</p>
      </div>
    </Box>
    <h2>{`Best practices`}</h2>
    <h3>{`Primary variant`}</h3>
    <p>{`Only use one primary button on a page, whenever possible, to indicate its emphasis relative to other actions.`}</p>
    <h3>{`General`}</h3>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/586552/217700259-ad2702e6-cf3d-4708-b62b-a44a6f54d9b2.png" role="presentation" width="456" alt="An image showing a button with no line breaks" />
    <Caption mdxType="Caption">Keep button labels succinct with no line breaks.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/586552/217700258-2ab8cdf3-22a2-4628-80dd-1489b28ea387.png" role="presentation" width="456" alt="An image showing a button with a line break" />
    <Caption mdxType="Caption">Buttons should never contain line breaks and lose their button shape.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/586552/217700257-558c4517-9df3-41a8-bc5c-4df9cca1d7d9.png" role="presentation" width="456" alt="A button using sentance case" />
    <Caption mdxType="Caption">Use sentence case for labels.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/586552/217700255-95b40748-a02a-4829-a373-aadc587e843d.png" role="presentation" width="456" alt="A button with text in all caps" />
    <Caption mdxType="Caption">Don't use all-caps or other text formats.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/586552/217700253-97df122e-b484-4ce8-a0cc-886d20cd8beb.png" role="presentation" width="456" alt="A button showing :focus state" />
    <Caption mdxType="Caption">Show focus styles on keyboard :focus</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/586552/217700252-064704cf-e8ff-46af-a859-acf114c3a19d.png" role="presentation" width="456" alt="A button with the default :focus styles removed" />
    <Caption mdxType="Caption">Don't remove default button :focus styles.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/586552/217700251-6b03cd5f-1fe9-4176-8347-35a1382ad9d3.png" role="presentation" width="456" alt="Primary button placed at the end of the button group" />
    <Caption mdxType="Caption">Do place primary buttons at the end of a button group</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/586552/217700250-b38baf90-bea5-470f-a69b-ec5ed32ca4d8.png" role="presentation" width="456" alt="Primary button placed at the start of the button group" />
    <Caption mdxType="Caption">Don't place primary buttons at the start of a button group</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/586552/217700248-e0db9c6c-05b6-488a-89b4-83f55ecaaaf1.png" role="presentation" width="456" alt="A primary button next to a secondary button" />
    <Caption mdxType="Caption">Use a primary button with a secondary button</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/586552/217700246-62b420c4-523b-4706-a7f6-8a0f219d9b7e.png" role="presentation" width="456" alt="Multiple primary buttons placed together" />
    <Caption mdxType="Caption">Don’t place multiple primary buttons together</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Selections`}</h3>
    <p>{`Buttons are commonly used to show a choice within a `}<a parentName="p" {...{
        "href": "/components/selectpanel"
      }}>{`SelectPanel`}</a>{` or `}<a parentName="p" {...{
        "href": "/components/action-menu"
      }}>{`ActionMenu`}</a>{`. In such instances, they are frequently paired with an internal or external label.`}</p>
    <img src="https://github.com/primer/design/assets/980622/81fce1c8-df1e-4b31-880b-0c947af4f018" role="presentation" alt="Two examples of dropdown buttons are shown. The left one has a label 'Visibility: Everybody' and is marked as 'Internal label'. The right one has an external label 'Visibility' above the button and a internal label 'Everybody', and is marked as 'External Label'." />
    <p>{`When dealing with multiple selections, it's essential to maintain clarity regarding the total selection at all times. We suggest showing the value directly within the button. However, in scenarios where multiple items are selected, transitioning to a format such as `}<inlineCode parentName="p">{`2 selected`}</inlineCode>{` is advisable.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/980622/03a67b8b-9546-4f70-a91e-676623b92d87" role="presentation" width="456" alt="Button with label 'Labels: 2 selected'" />
    <Caption mdxType="Caption">Use the '2 selected' format when multiple items are selected</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/980622/007b5564-f5ea-48b2-8f80-3935ccf3b5be" role="presentation" width="456" alt="Button with label 'Labels: feature-request,bu...'" />
    <Caption mdxType="Caption">Don’t comma seperate multiple selections</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`Buttons must have a clear and descriptive label. The label is the visible content of the button and will usually be text. The label should be concise and descriptive of the action that will be performed when the button is activated. This label will be used as the button's accessible name.`}</p>
    <p>{`When using Octicons for leading and trailing visuals, note that icons don't have any text alternative. They are purely visual, and not conveyed to screen readers. Don't rely on these icons alone to convey meaning – make sure that the text label of the button provides sufficient meaning/context on its own.`}</p>
    <p><inlineCode parentName="p">{`Button`}</inlineCode>{` has different schemes/variants (such as `}<inlineCode parentName="p">{`danger`}</inlineCode>{`, `}<inlineCode parentName="p">{`primary`}</inlineCode>{`, `}<inlineCode parentName="p">{`invisible`}</inlineCode>{`), which result in different text, background, and border colours. Note that these differences are purely visual - they may be difficult to distinguish for users with impaired color perception, and won't be exposed at all to screen reader users. For this reason, you can't rely on the scheme/variant alone to give meaning to your content. Make sure that the text label of the button provides sufficient meaning/context on its own, regardless of its visual presentation.`}</p>
    <p>{`The button must have a minimum target size of 24×24 CSS pixels. This is to ensure that the button is large enough to be easily activated by users with motor impairments.`}</p>
    <h3>{`Do not disable buttons`}</h3>
    <p>{`There are rare cases where it's ok to disable a button, but it should generally be avoided. In forms mode, they won't be discovered as they won't take keyboard focus.`}</p>
    <p>{`For more information on disabled controls, see GitHub's `}<a parentName="p" {...{
        "href": "https://accessibility-playbook.github.com/link-and-button-guidance#disabling-links-and-buttons"
      }}>{`link and button a11y guidance`}</a>{` (GitHub staff only).`}</p>
    <h3>{`Inactive buttons and `}<inlineCode parentName="h3">{`aria-disabled`}</inlineCode></h3>
    <p>{`An `}<a parentName="p" {...{
        "href": "#inactive"
      }}>{`inactive`}</a>{` button `}<strong parentName="p">{`should not be conveyed as disabled`}</strong>{` with `}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{` if it performs an action when activated. For example, showing a dialog with more info about why the button is inactive.`}</p>
    <p>{`An `}<a parentName="p" {...{
        "href": "#inactive"
      }}>{`inactive`}</a>{` button `}<strong parentName="p">{`may be conveyed as disabled`}</strong>{` with `}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{` if it does not perform an action when activated.`}</p>
    <h3>{`Descriptive buttons`}</h3>
    <p>{`Labeling buttons properly lets users know what will happen when they activate the control, lessens errors, and increases confidence.`}</p>
    <p>{`Read more about `}<a parentName="p" {...{
        "href": "/guides/accessibility/descriptive-buttons"
      }}>{`descriptive buttons`}</a>{`.`}</p>
    <h3>{`Button loading state`}</h3>
    <img width="960" alt="A button in a loading state with a11y annotations" src="https://github.com/primer/design/assets/2313998/87f87136-1414-4ae7-8e4b-1b4d6e6b2d20" />
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      alignItems: 'start',
      gap: 3,
      mt: 3,
      mb: 3
    }} mdxType="Box">
      <Box flex={1} mdxType="Box">
        <p>{`When implementing a "loading" button state, don't remove the button from the DOM or pass the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` attribute. Doing so would make it impossible to tab to the button. If the button was just focused and activated, it would reset focus. Resetting focus would disrupt the keyboard navigation flow, and creates a confusing experience for assistive technologies such as screen readers.`}</p>
        <p>{`Once the button is activated (and is in a loading state), it get the attribute `}<inlineCode parentName="p">{`aria-disabled="true"`}</inlineCode>{`.`}</p>
        <p>{`A separate, visually hidden element should be rendered outside of the `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` with a message to communicate the loading status. For example, "Saving profile".`}</p>
      </Box>
      <CustomVideoPlayer loop autoPlay width="350" src="https://github.com/primer/design/assets/2313998/b138567f-3ba8-49ab-9fa1-80a4fe30b034" mdxType="CustomVideoPlayer" />
    </Box>
    <p>{`This message should be in an ARIA live region, using `}<inlineCode parentName="p">{`aria-live="polite"`}</inlineCode>{`. The live region must be present on page load, but the message inside the live region should only be rendered while the button is in a loading state.`}</p>
    <p>{`If an error prevents process from being completed, focus should be brought to an `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{` (or next relevant heading) of the error banner. `}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`The component is rendered as a regular `}<inlineCode parentName="p">{`<button> … </button>`}</inlineCode>{` element. The content passed to the component is used as the button's accessible name.`}</p>
    <p>{`In rare cases when the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` property is set, the component is rendered as a standard `}<inlineCode parentName="p">{`<button disabled> … </button>`}</inlineCode>{` disabled button.`}</p>
    <p>{`In rare cases when the `}<inlineCode parentName="p">{`inactive`}</inlineCode>{` property is set, the component is rendered as `}<inlineCode parentName="p">{`<button aria-disabled="true"> … </button>`}</inlineCode>{` – the control itself is visually styled to appear disabled. However, the control is still keyboard-focusable, and can still be activated. `}</p>
    <p>{`The selectable variant/scheme colors meet minimum color contrast requirements.`}</p>
    <p>{`The medium and large variants meet the minimum target size requirement. However, the small button variant may currently fall below the minimum requirements - see the implementation requirements section for details.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`When using a trailing action icon, the icon will lack a text alternative. If the trailing action is used to indicate that the button will have a particular effect, such as opening a dropdown, you will need to add additional programmatic information to the button to convey this information to screen reader users – for instance, by adding an `}<inlineCode parentName="p">{`aria-haspopup`}</inlineCode>{` attribute, and conveying the current state of the dropdown (whether it's expanded or collapsed) with `}<inlineCode parentName="p">{`aria-expanded`}</inlineCode>{`.`}</p>
    <p>{`When using the small button variant, make sure that the resulting button has an appropriate width. While the padding of the medium and large button variants is sufficient to meet this requirement, no matter the content of the button, the padding of the small button variant is not sufficient if the content passed to the button is extremely narrow. For example, a single letter "i" would result in a button width of 21.5 CSS pixels. Ensure that the content of the button's small variation is wide enough to meet this requirement.`}</p>
    <p>{`When providing an accessible name (via `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`) that overrides the visually-presented label, make sure that the accessible name includes the visible label in totality. This ensures that speech-input users can activate the control using information that is visually available. This approach should be used with caution – make sure that the visible label and the accessible name remain in sync.`}</p>
    <p>{`When a button's visible label changes as a result of it being activated, make sure that this information is also communicated to assistive technology users. For example, if a `}<inlineCode parentName="p">{`Star`}</inlineCode>{` button is activated, and the visual label dynamically changes to `}<inlineCode parentName="p">{`Starred`}</inlineCode>{`, this change must be conveyed to screen reader users. Currently, when the label/accessible name of the currently focused element is dynamically updated, this change is not consistently announced, and additional work will be required to ensure that screen reader users are notified of the change. For more details, refer to `}<a parentName="p" {...{
        "href": "https://github.com/github/accessibility/blob/85b3a929bfcb9bc5a3fd7b18c83b12d0c38a775e/docs/wiki/screen-reader-testing/dynamically-updated-buttons-support-april-2024.md"
      }}>{`Staff-only: Dynamically updating button labels`}</a>{`.
In the specific scenario where the button's accessible name is coming from an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`, remember to update the `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` when the visual label is updated. This should trigger an announcement without the use of a live region. Refer to `}<a parentName="p" {...{
        "href": "https://github.com/github/accessibility/blob/85b3a929bfcb9bc5a3fd7b18c83b12d0c38a775e/docs/wiki/screen-reader-testing/dynamically-updated-buttons-support-april-2024.md"
      }}>{`Staff-only: Dynamically updating button labels`}</a>{`.`}</p>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">{`The button has a sufficiently descriptive label`}</li>
      <li parentName="ul">{`If the button includes icons for its leading or trailing visuals, the button's purpose is clear even without the icons (as these are only decorative and are not conveyed to screen reader users)`}</li>
      <li parentName="ul">{`If the button uses a schema/variant for a particular presentation, the button does not solely rely on this visual aspect to convey its purpose, or to differentiate it from other buttons`}</li>
      <li parentName="ul">{`If the button uses a trailing action icon to visually hint at its functionality, it also conveys this additional information programmatically through the use of appropriate `}<inlineCode parentName="li">{`aria-*`}</inlineCode>{` attributes`}</li>
      <li parentName="ul">{`For the small variation (which currently doesn't enforce an appropriate minimum size), the button has a minimum target size of 24×24 CSS pixels`}</li>
      <li parentName="ul">{`If the button has an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` that overrides the visually-presented label, the accessible name includes the visible label allowing speech input users to activate the control based on visual information.`}</li>
      <li parentName="ul">{`If the button's visible label is updated communicating that a change has taken place, make sure that this change is also communicated to assistive technology users through appropriate techniques.`}</li>
    </ul>
    <h4>{`Component tests`}</h4>
    <ul>
      <li parentName="ul">{`Content passed into the component is output as the button's visible label`}</li>
      <li parentName="ul">{`Unless the button is `}<inlineCode parentName="li">{`disabled`}</inlineCode>{`, it can be focused and activated using the keyboard`}</li>
      <li parentName="ul">{`If the button is set to be `}<inlineCode parentName="li">{`inactive`}</inlineCode>{`, it still receives keyboard focus, but conveys its inactive/disabled state to screen readers with the `}<inlineCode parentName="li">{`aria-disabled="true"`}</inlineCode>{` attribute`}</li>
      <li parentName="ul">{`The button has a minimum target size of 24×24 CSS pixels, regardless of content`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="Button" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      